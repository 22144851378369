<template>
  <section class="blog-cart-road-background">
    <div v-if="blogs" class="blog-cart-wrapper">
      <div
        v-for="(blog, index) in blogs"
        :key="index"
        class="blog-cart-item-wrap"
      >
        <div>
          <div class="blog-cart-item blog-white-cart-background">
            <p class="blog-cart-item-title">
              {{ blog.title }}
            </p>
            <p class="blog-cart-item-preview">
              {{ blog.preview }}
            </p>
            <p>
              <router-link
                class="blog-cart-item-btn"
                :to="`/blogs/` + blog.blogId"
                >Читать далее
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "BlogList",
  data() {
    return {
      blogs: {}
    };
  },
  computed: {},
  mounted() {
    axios
      .get(process.env.VUE_APP_BACKEND_ROOT_URL + "/blogs")
      .then(response => {
        this.blogs = response.data;
      });
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/blog-list.scss";
</style>
