<template>
  <main>
    <BlogList />
  </main>
</template>

<script>
import BlogList from "../components/BlogList.vue";

export default {
  components: {
    BlogList
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/module.scss";
</style>
